<template>
  <div class="wizard-v3-content">
    <v-container>
      <Header v-if="page === 2" />
      <v-row>
        <v-col>
          <v-alert dense type="info" v-if="approve && approve > 0">
            {{
              approve == 1
                ? "ส่งข้อมูลการสมัครเรียนเสร็จสิ้น กรุณาติดต่อเพื่อชำระค่าสมัครภายใน 7 วัน ติดต่อสอบถามโทร 043-237463 "
                : approve == 2
                ? "เจ้าหน้าที่รับลงทะเบียนเสร็จสิ้น"
                : ""
            }}
          </v-alert>
        </v-col>
      </v-row>
      <div class="wizard-form" v-if="page === 0">
        <div class="form-register">
          <v-row>
            <v-img
              :src="require('../assets/sliderx14.jpg')"
              class="cover-image"
              style=""
            />
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center" cols="12">
              โปรดระบุรหัสประจำตัวประชาชน
            </v-col>
            <v-col
              class="d-flex justify-center"
              style="margin-left: 30px; margin-right: 30px"
            >
              <v-text-field
                :success="validate.v_person_number"
                :success-messages="
                  this.validate.v_person_number ? ['ข้อมูลถูกต้อง'] : []
                "
                type="id"
                maxlength="13"
                v-model="person_number"
                label="รหัสประจำตัวประชาชน"
                hint="กรุณากรอกรหัสประจำตัวประชาชน 13 หลัก"
                persistent-hint
                @keydown.enter="regisPersonId()"
                ref="person_number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row style="padding-bottom: 50px">
            <v-col
              class="d-flex justify-end"
              style="margin: -20px 30px 30px 30px"
            >
              <v-btn
                :disabled="!validate.v_person_number"
                color="info"
                @click="regisPersonId()"
              >
                {{ loading ? "..." : "Next Step" }}
                <!-- <v-img src="@/assets/balls.svg" class="icon-text-center" /> -->
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-container v-if="page === 1" style="margin-bottom: 10px">
        <div>
          <v-row>
            <v-col><span class="headline">สาขาวิชาที่เลือก</span></v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center" cols="12">
              <v-radio-group v-model="branch" row>
                <v-radio
                  label="ปวช."
                  value="1"
                  @click="data_branch = ''"
                ></v-radio>
                <v-radio
                  label="ปวส."
                  value="2"
                  @click="data_branch = ''"
                ></v-radio>
                <v-radio
                  label="ปวส. (รอบวันอาทิตย์)"
                  value="3"
                  @click="data_branch = ''"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col v-if="branch == 1" class="d-flex justify-center" cols="12">
              <v-select
                v-model="data_branch"
                :items="items_one"
                label="กรุณาเลือกสาขาวิชา ปวช."
              ></v-select>
            </v-col>
            <v-col v-if="branch == 2" class="d-flex justify-center" cols="12">
              <v-select
                v-model="data_branch"
                :items="items_two"
                label="กรุณาเลือกสาขาวิชา ปวส."
              ></v-select>
            </v-col>
            <v-col v-if="branch == 3" class="d-flex justify-center" cols="12">
              <v-select
                v-model="data_branch"
                :items="items_three"
                label="กรุณาเลือกสาขาวิชา ปวส. (รอบวันอาทิตย์)"
              ></v-select>
            </v-col>
          </v-row>
          <div>
            <v-btn
              @click="selectBranch()"
              block
              color="primary"
              :disabled="status.btn_branch"
              >ยืนยัน</v-btn
            >
          </div>
        </div>
      </v-container>
      <div class="form-register" v-if="page === 2" style="margin-bottom: 30px">
        <div class="row steps clearfix">
          <ul role="tablist d-flex justify-content-between" style="width: 100%">
            <li
              role="tab"
              aria-disabled="false"
              :class="{ first: true, current: e1 > 0 }"
              aria-selected="true"
            >
              <a
                id="form-total-t-0"
                href="#"
                aria-controls="form-total-p-0"
                @click.prevent="nextStep(0)"
                ><span class="current-info audible"> </span>
                <div class="title">
                  <span class="step-icon"><v-icon>fas fa-user</v-icon></span>
                  <span class="step-text hidden-sm-and-down"
                    >ข้อมูลส่วนตัว</span
                  >
                </div></a
              >
            </li>
            <li role="tab" :class="{ current: e1 > 1 }" aria-disabled="false">
              <a
                id="form-total-t-1"
                href="#"
                aria-controls="form-total-p-1"
                @click.prevent="nextStep(1)"
                ><div class="title">
                  <span class="step-icon"><v-icon>fas fa-book</v-icon></span>
                  <span class="step-text hidden-sm-and-down"
                    >ข้อมูลที่อยู่</span
                  >
                </div></a
              >
            </li>
            <li role="tab" :class="{ current: e1 > 2 }" aria-disabled="false">
              <a
                id="form-total-t-2"
                href="#"
                aria-controls="form-total-p-2"
                @click.prevent="nextStep(2)"
                ><div class="title">
                  <span class="step-icon"><v-icon>fas fa-users</v-icon></span>
                  <span class="step-text hidden-sm-and-down"
                    >ข้อมูลผู้ปกครอง</span
                  >
                </div></a
              >
            </li>
            <li
              role="tab"
              :class="{ current: e1 > 3 }"
              aria-disabled="false"
              class="last"
            >
              <a
                id="form-total-t-3"
                href="#"
                aria-controls="form-total-p-3"
                @click.prevent="nextStep(3)"
                ><div class="title">
                  <span class="step-icon"
                    ><v-icon>fas fa-file-alt</v-icon></span
                  >
                  <span class="step-text hidden-sm-and-down"
                    >ข้อมูลเอกสารหลักฐาน</span
                  >
                </div></a
              >
            </li>
          </ul>
        </div>
        <v-stepper v-model="e1">
          <v-stepper-items>
            <v-stepper-content :key="`${1}-content`" :step="1">
              <div>
                <div>
                  <v-row>
                    <v-col class="d-flex justify-center" cols="12">
                      <v-text-field
                        maxlength="13"
                        v-model="data_branch"
                        label="สาขา"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="12">
                      <v-text-field
                        maxlength="13"
                        v-model="person_number"
                        label="รหัสประจำตัวประชาชน"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-select
                        :success="register.title_name_th !== ''"
                        :success-messages="
                          register.title_name_th !== ''
                            ? ['กรอกข้อมูลแล้ว']
                            : []
                        "
                        v-model="register.title_name_th"
                        :items="items_th"
                        label="คำนำหน้าชื่อ(TH)"
                        :disabled="approve >= 1"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="validate.v_firstname_stu_th"
                        :success-messages="
                          validate.v_firstname_stu_th ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @input="validateText('fth')"
                        v-model="register.firstname_th"
                        label="ชื่อ (TH)"
                        hint="ชื่อภาษาไทย"
                        persistent-hint
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="validate.v_lastname_stu_th"
                        :success-messages="
                          validate.v_lastname_stu_th ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @input="validateText('lth')"
                        v-model="register.lastname_th"
                        label="นามสกุล (TH)"
                        hint="นามสกุลภาษาไทย"
                        persistent-hint
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" sm="4">
                      <v-select
                        :success="register.title_name_eng !== ''"
                        :success-messages="
                          register.title_name_eng !== ''
                            ? ['กรอกข้อมูลแล้ว']
                            : []
                        "
                        v-model="register.title_name_eng"
                        :items="items_eng"
                        label="คำนำหน้าชื่อ (ENG)"
                        :disabled="approve >= 1"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="validate.v_firstname_stu_eng"
                        :success-messages="
                          validate.v_firstname_stu_eng ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @input="validateText('feng')"
                        v-model="register.firstname_eng"
                        label="ชื่อ (ENG)"
                        hint="ชื่อภาษาอังกฤษ"
                        persistent-hint
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="validate.v_lastname_stu_eng"
                        :success-messages="
                          validate.v_lastname_stu_eng ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @input="validateText('leng')"
                        v-model="register.lastname_eng"
                        hint="นามสกุลภาษาอังกฤษ"
                        persistent-hint
                        label="นามสกุล (ENG)"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- <v-col cols="12" md="3" sm="2">
                        <v-text-field
                          :success="validate.v_nickname_stu_th"
                          :success-messages="
                            validate.v_nickname_stu_th ? ['กรอกข้อมูลแล้ว'] : []
                          "
                          @input="validateText('nth')"
                          v-model="register.nickname"
                          label="ชื่อเล่น (ภาษาไทย)"
                                                    :disabled="approve >= 1"
                        ></v-text-field>
                      </v-col> -->
                    <v-col>
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="picker"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :success="register.age !== 0 && register.age > 0"
                            :success-messages="
                              register.age !== 0 && register.age > 0
                                ? ['กรอกข้อมูลแล้ว']
                                : []
                            "
                            v-model="show_picker"
                            label="วันเดือนปีเกิด"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :disabled="approve >= 1"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="picker"
                          scrollable
                          locale="th-th"
                          @change="formatDate(picker)"
                          :disabled="approve >= 1"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(picker)"
                          >
                            ตกลง
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="2" sm="2">
                      <v-text-field
                        :success="register.age !== 0"
                        :success-messages="
                          register.age !== 0 ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="register.age"
                        label="อายุ(ปี)"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="2" sm="2">
                        <v-select
                          :success="register.religion !== ''"
                          :success-messages="
                            register.religion !== '' ? ['กรอกข้อมูลแล้ว'] : []
                          "
                          v-model="register.religion"
                          label="ศาสนา"
                                                    :items="item_religion"
                          :disabled="approve >= 1"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          cols="12"
                          md="4"
                          sm="4"
                          :success="register.race !== ''"
                          :success-messages="
                            register.race !== '' ? ['กรอกข้อมูลแล้ว'] : []
                          "
                          v-model="register.race"
                          label="เชื้อชาติ"
                                                    :items="item_race"
                          :disabled="approve >= 1"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          cols="12"
                          md="4"
                          sm="4"
                          :success="register.nationality !== ''"
                          :success-messages="
                            register.nationality !== ''
                              ? ['กรอกข้อมูลแล้ว']
                              : []
                          "
                          v-model="register.nationality"
                          label="สัญชาติ"
                                                    :items="item_nationality"
                        ></v-select>
                      </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-select
                        v-model="register.rank_education"
                        :success="register.rank_education !== ''"
                        :success-messages="
                          register.rank_education !== ''
                            ? ['กรอกข้อมูลแล้ว']
                            : []
                        "
                        :items="item_education"
                        label="จบการศึกษาระดับ"
                        @change="showEducation()"
                        :disabled="approve >= 1"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-autocomplete
                        :success="register.school_province !== ''"
                        :success-messages="
                          register.school_province !== ''
                            ? ['กรอกข้อมูลแล้ว']
                            : []
                        "
                        v-model="register.school_province"
                        :items="items_jangwat"
                        item-value="id"
                        item-text="name"
                        filled
                        label="จังหวัด"
                        @change="showJangwatSchool(register.school_province)"
                        :disabled="approve >= 1"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        :success="register.school_name !== ''"
                        :success-messages="
                          register.school_name !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="register.school_name"
                        label="ชื่อสถานศึกษา"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        :success="validate.v_gpa"
                        :success-messages="
                          validate.v_gpa ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @input="validateGpa()"
                        v-model="register.gpa"
                        label="GPA (เกรดเฉลี่ย)"
                        placeholder="0.00"
                        hint="กรอกเป็นทศนิยม 2 ตำแหน่ง"
                        persistent-hint
                        maxlength="4"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn @click="page = 1" class="mr-2" plain> Previous </v-btn>
                  <v-btn color="info" @click="nextStep(1)"> Next Step </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :key="`${2}-content`" :step="2">
              <div>
                <div>
                  <v-row>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="address.id_house !== ''"
                        :success-messages="
                          address.id_house !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="address.id_house"
                        label="บ้านเลขที่"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="address.moo !== ''"
                        :success-messages="
                          address.moo !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="address.moo"
                        label="หมู่ที่"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="address.name_mooban !== ''"
                        :success-messages="
                          address.name_mooban !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="address.name_mooban"
                        label="ชื่อหมู่บ้าน"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="address.road !== ''"
                        :success-messages="
                          address.road !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="address.road"
                        label="ถนน"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-autocomplete
                        :success="address.province !== ''"
                        :success-messages="
                          address.province !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @change="
                          fetchAumphoe(address.province),
                            showJangwat(address.province)
                        "
                        v-model="address.province"
                        :items="items_jangwat"
                        item-value="id"
                        item-text="name"
                        filled
                        label="จังหวัด"
                        :disabled="approve >= 1"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-autocomplete
                        :success="address.county !== ''"
                        :success-messages="
                          address.county !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @change="
                          fetchTambon(address.province, address.county),
                            showAmper(address.county)
                        "
                        v-model="address.county"
                        :items="items_aumper"
                        item-value="id"
                        item-text="name"
                        label="อำเภอ/เขต"
                        filled
                        :disabled="approve >= 1"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-autocomplete
                        :success="address.district !== ''"
                        :success-messages="
                          address.district !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @click="showTambon(address.district)"
                        v-model="address.district"
                        :items="items_tambon"
                        item-value="id"
                        item-text="name"
                        label="ตำบล"
                        filled
                        :disabled="approve >= 1"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-autocomplete
                        :success="address.id_address !== ''"
                        :success-messages="
                          address.id_address !== '' ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="address.id_address"
                        :items="items_postcode"
                        @change="showIdAddress(address.id_address)"
                        label="รหัสไปรษณีย์"
                        item-value="id"
                        item-text="name"
                        filled
                        :disabled="approve >= 1"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="validate.v_phone_student"
                        :success-messages="
                          validate.v_phone_student ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        v-model="address.phone_std"
                        maxlength="10"
                        @input="validatePhoneNumber('std')"
                        label="เบอร์โทรศัพท์นักเรียน"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-text-field
                        :success="validate.v_email"
                        :success-messages="
                          validate.v_email ? ['กรอกข้อมูลแล้ว'] : []
                        "
                        @input="validateEmail()"
                        v-model="address.email"
                        label="E-mail"
                        :disabled="approve >= 1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- <Address /> -->
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn @click="backStep(2)" class="mr-2" plain>
                    Previous
                  </v-btn>
                  <v-btn color="info" @click="nextStep(2)"> Next Step </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :key="`${3}-content`" :step="3">
              <div style="min-height: 300px">
                <!-- พ่อ แม่ เบอร์ติดต่อผู้ปกครอง ข้อมูล -->
                <v-row>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      :success="parents.first_name_father !== ''"
                      :success-messages="
                        parents.first_name_father !== ''
                          ? ['กรอกข้อมูลแล้ว']
                          : []
                      "
                      v-model="parents.first_name_father"
                      label="ชื่อบิดา"
                      :disabled="approve >= 1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      :success="parents.last_name_father !== ''"
                      :success-messages="
                        parents.last_name_father !== ''
                          ? ['กรอกข้อมูลแล้ว']
                          : []
                      "
                      v-model="parents.last_name_father"
                      label="นามสกุลบิตา"
                      :disabled="approve >= 1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      :success="parents.first_name_mother !== ''"
                      :success-messages="
                        parents.first_name_mother !== ''
                          ? ['กรอกข้อมูลแล้ว']
                          : []
                      "
                      v-model="parents.first_name_mother"
                      label="ชื่อมารดา"
                      :disabled="approve >= 1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      :success="parents.last_name_mother !== ''"
                      :success-messages="
                        parents.last_name_mother !== ''
                          ? ['กรอกข้อมูลแล้ว']
                          : []
                      "
                      v-model="parents.last_name_mother"
                      label="นามสกุลมารดา"
                      :disabled="approve >= 1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      :success="validate.v_phone_parents"
                      :success-messages="
                        validate.v_phone_parents ? ['กรอกข้อมูลแล้ว'] : []
                      "
                      maxlength="10"
                      @input="validatePhoneNumber('par')"
                      v-model="parents.phone_parents"
                      label="เบอร์ติดต่อผู้ปกครอง"
                      :disabled="approve >= 1"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn @click="backStep(3)" class="mr-2" plain>
                    Previous
                  </v-btn>
                  <v-btn color="info" @click="nextStep(3)"> Next Step </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :key="`${4}-content`" :step="4">
              <div style="min-height: 300px">
                <v-row>
                  <v-col cols="12" lg="4" md="6">
                    <v-file-input
                      :success="files[0].bin"
                      :success-messages="files[0].bin ? ['สำเร็จ'] : []"
                      label="บัตรประจำตัวประชาชน"
                      v-model="files[0].bin"
                      :disabled="approve >= 1"
                      @change="() => this.uploadFileSync(0)"
                    ></v-file-input>
                    <v-progress-linear
                      buffer-value="0"
                      :value="files[0].progress"
                      stream
                      color="cyan"
                      v-if="files[0].progress != 0 && files[0].progress != 100"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-file-input
                      :success="files[1].bin"
                      :success-messages="files[1].bin ? ['สำเร็จ'] : []"
                      label="ทะเบียนบ้าน"
                      v-model="files[1].bin"
                      :disabled="approve >= 1"
                      @change="() => this.uploadFileSync(1)"
                    ></v-file-input>
                    <v-progress-linear
                      buffer-value="0"
                      :value="files[1].progress"
                      stream
                      color="cyan"
                      v-if="files[1].progress != 0 && files[1].progress != 100"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-file-input
                      :success="files[2].bin"
                      :success-messages="files[2].bin ? ['สำเร็จ'] : []"
                      label="ภาพถ่ายสองนิ้ว"
                      v-model="files[2].bin"
                      :disabled="approve >= 1"
                      @change="() => this.uploadFileSync(2)"
                    ></v-file-input>
                    <v-progress-linear
                      buffer-value="0"
                      :value="files[2].progress"
                      stream
                      color="cyan"
                      v-if="files[2].progress != 0 && files[2].progress != 100"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-file-input
                      :success="files[3].bin"
                      :success-messages="files[3].bin ? ['สำเร็จ'] : []"
                      label="ใบ ปพ.1, รบ.1 หรือ ใบเกรดเฉลี่ยรวม"
                      v-model="files[3].bin"
                      :disabled="approve >= 1"
                      @change="() => this.uploadFileSync(3)"
                    ></v-file-input>
                    <v-progress-linear
                      buffer-value="0"
                      :value="files[3].progress"
                      stream
                      color="cyan"
                      v-if="files[3].progress != 0 && files[3].progress != 100"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-file-input
                      :success="files[4].bin"
                      :success-messages="files[4].bin ? ['สำเร็จ'] : []"
                      label="บัตรประชาชนผู้ปกครอง"
                      v-model="files[4].bin"
                      :disabled="approve >= 1"
                      @change="() => this.uploadFileSync(4)"
                    ></v-file-input>
                    <v-progress-linear
                      buffer-value="0"
                      :value="files[4].progress"
                      stream
                      color="cyan"
                      v-if="files[4].progress != 0 && files[4].progress != 100"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <hr />
                <v-row class="mt-2">
                  <v-col
                    cols="6"
                    lg="4"
                    md="4"
                    v-for="(item, i) in fileUpload"
                    :key="i"
                  >
                    <v-img
                      max-height="150"
                      max-width="250"
                      :src="`https://reg.tkk.ac.th/public/api/document/${item.path}`"
                    />
                    <p>
                      <v-icon class="mr-2 mt-1">fas fa-file-alt</v-icon>
                      {{ item.type }}
                    </p>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn @click="backStep(4)" class="mr-2" plain>
                    Previous
                  </v-btn>
                  <v-btn color="success" @click="nextStep(4)">
                    Next Step
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
      <div v-if="page === 3">
        <div style="padding: 30px">
          <v-row>
            <v-col>
              <h3 class="text-center">
                หลังจากส่งข้อมูล ท่านสามารถแก้ไขเปลี่ยนแปลงข้อมูลได้ภายใน 7 วัน
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4 class="text-center" style="margin-top: -20px; color: red">
                กรุณาตรวจสอบข้อมูลของท่านก่อนกด "ส่งข้อมูล"
              </h4>
            </v-col>
          </v-row>
          <v-row style="border: 1px solid black">
            <v-col cols="12">
              <h4 class="">
                <u>ข้อมูลการสมัคร</u>
              </h4>
              <v-col style="margin-top: -20px" cols="12" sm="6">
                <span style="font-weight: bold; margin-left: 10px"
                  >ระดับ &nbsp;<br /></span
                ><span style="margin-left: 25px"
                  >{{
                    branch == 1
                      ? "ปวช."
                      : branch == 2
                      ? "ปวส."
                      : "ปวส (รอบวันอาทิตย์)"
                  }}&nbsp;&nbsp;</span
                >
              </v-col>
              <v-col style="margin-top: -20px" cols="12" sm="6">
                <span style="font-weight: bold; margin-left: 10px"
                  >สาขา &nbsp;<br /></span
                ><span style="margin-left: 25px"
                  >{{ data_branch }}&nbsp;&nbsp;</span
                >
              </v-col>
            </v-col>
          </v-row>
          <v-row style="border: 1px solid black">
            <v-col cols="12">
              <h4 class="">
                <u>ข้อมูลส่วนตัว</u>
              </h4>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >ชื่อภาษาไทย &nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ register.title_name_th }} {{ register.firstname_th }}
                {{ register.lastname_th }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >ชื่อภาษาอังกฤษ &nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ register.title_name_eng }} {{ register.firstname_eng }}
                {{ register.lastname_eng }}&nbsp;&nbsp;</span
              >
            </v-col>
            <!-- <v-col style="margin-top: -20px;" cols="12" sm="6">
                <span style="font-weight: bold; margin-left: 10px;"
                  >ชื่อเล่น &nbsp;<br /></span
                ><span style="margin-left: 25px;">{{ register.nickname }}</span>
              </v-col> -->
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >รหัสประจำตัวประชาชน&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ person_number }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >วันเดือนปีเกิด&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ show_picker }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >จบการศึกษาระดับ&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ show_rank_education }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >ชื่อสถานศึกษา&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ register.school_name }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >จังหวัด&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ show_school_province }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >เกรดเฉลี่ย&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ register.gpa }}&nbsp;&nbsp;</span
              >
            </v-col>
            <v-col cols="12"><hr /></v-col>
            <v-col cols="12" style="margin-top: -20px">
              <h4 class="">
                <u>ข้อมูลที่อยู่</u>
              </h4>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="12">
              <span style="font-weight: bold; margin-left: 10px"
                >บ้านเลขที่&nbsp;</span
              ><span>{{ address.id_house }}&nbsp;</span
              ><span style="font-weight: bold">หมู่ที่&nbsp;</span
              ><span>{{ address.moo }}&nbsp;</span
              ><span style="font-weight: bold">หมู่บ้าน&nbsp;</span
              ><span>{{ address.name_mooban }}&nbsp;</span
              ><span style="font-weight: bold">ถนน&nbsp;</span
              ><span>{{ address.road }}&nbsp;</span
              ><span style="font-weight: bold">ตำบล&nbsp;</span
              ><span>{{ show_district }}&nbsp;</span
              ><span style="font-weight: bold">อำเภอ/เขต&nbsp;</span
              ><span>{{ show_county }}&nbsp;</span
              ><span style="font-weight: bold">จังหวัด&nbsp;</span
              ><span>{{ show_province }}&nbsp;</span
              ><span style="font-weight: bold">รหัสไปรษณีย์&nbsp;</span
              ><span>{{ show_id_address }}&nbsp;<br /></span>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >เบอร์โทรศัพท์นักเรียน&nbsp;</span
              ><span>{{ address.phone_std }}&nbsp;</span>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >E-mail&nbsp;</span
              ><span>{{ address.email }}&nbsp;</span>
            </v-col>
            <v-col cols="12"><hr /></v-col>
            <v-col cols="12" style="margin-top: -20px">
              <h4 class="">
                <u>ข้อมูลผู้ปกครอง</u>
              </h4>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >ชื่อบิดา&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ parents.first_name_father }}
                {{ parents.last_name_father }}&nbsp;<br
              /></span>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >ชื่อมารดา&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ parents.first_name_mother }}
                {{ parents.last_name_mother }}&nbsp;<br
              /></span>
            </v-col>
            <v-col style="margin-top: -20px" cols="12" sm="6">
              <span style="font-weight: bold; margin-left: 10px"
                >เบอร์ติดต่อผู้ปกครอง&nbsp;<br /></span
              ><span style="margin-left: 25px"
                >{{ parents.phone_parents }}&nbsp;</span
              >
            </v-col>
            <v-col cols="12"><hr /></v-col>
            <v-col cols="12" style="margin-top: -20px">
              <h4 class="">
                <u>ข้อมูลเอกสารหลักฐาน</u>
              </h4>
            </v-col>
            <v-col
              cols="6"
              lg="4"
              md="4"
              v-for="(item, i) in fileUpload"
              :key="i"
            >
              <v-img
                max-height="150"
                max-width="250"
                :src="`https://reg.tkk.ac.th/public/api/document/${item.path}`"
              />
              <p>
                <v-icon class="mr-2 mt-1">fas fa-file-alt</v-icon>
                {{ item.type }}
              </p>
            </v-col>
          </v-row>
          <v-row style="padding-bottom: 50px">
            <v-col class="d-flex justify-end">
              <v-btn
                class="mr-2"
                color="second"
                @click="
                  () => {
                    this.page = 2;
                  }
                "
                plain
              >
                Previous</v-btn
              >
              <v-btn color="primary" :disabled="approve >= 1" @click="submit">
                {{ loading ? "..." : "SUBMIT" }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog.open" width="500">
      <v-card>
        <v-card-title class="headline error lighten-2">
          การส่งข้อมูลล้มเหลว
        </v-card-title>

        <v-card-text class="mt-2">
          {{ dialog.text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.open = false">
            เข้าใจแล้ว
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import {
  fetchProvince,
  fetchAmphoe,
  fetchTambon,
  fetchPostcode,
} from "../services/address";
import { fetchInfo, saveStage, uploadFile, submit } from "../services/register";
import Header from "./Header";
export default {
  components: {
    Header,
  },
  data() {
    return {
      snackbar: {
        open: false,
        timeout: 3000,
        text: "",
        color: "green",
      },
      dialog: {
        open: false,
        text: "",
      },
      tooltip: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
      },
      loading: false,
      approve: 0,
      validate: {
        v_person_number: false,
        v_phone_student: false,
        v_email: false,
        v_phone_parents: false,
        v_firstname_stu_th: false,
        v_lastname_stu_th: false,
        v_firstname_stu_eng: false,
        v_lastname_stu_eng: false,
        v_nickname_stu_th: false,
        v_gpa: false,
      },
      branch: "",
      data_branch: "",
      status: {
        btn_branch: true,
        btn_person_id: true,
      },
      e1: 1,
      steps: 1,
      page: 0,
      person_number: "",
      import_jangwat: [],
      fixtest: {
        _token: "BiZibWmCvKHpHxN3oaNPmz5sm58w6iCiuBojMhpG",
        arg: "040",
      },
      items_one: [
        "สาขาวิชาการบัญชี | การบัญชี",
        "สาขาวิชาเทคโนโลยีสารสนเทศ | คอมพิวเตอร์กราฟิก เกม และแอนิเมชัน",
        "สาขาวิชาช่างกลโรงงาน | เครื่องมือกล",
        "สาขาวิชาช่างไฟฟ้ากำลัง | ไฟฟ้ากำลัง",
        "สาขาวิชาการตลาด | การตลาด",
        "สาขาวิชาภาษาต่างประเทศธุรกิจบริการ",
        "สาขาวิชาเทคโนโลยีธุรกิจดิจิทัล",
      ],
      items_two: [
        "สาขาวิชาการบัญชี | การบัญชี",
        "สาขาวิชาเทคโนโลยีธุรกิจดิจิทัล | ธุรกิจอีคอมเมิร์ซ (คอมพิวเตอร์ธุรกิจ)",
        "สาขาวิชาเทคนิคการผลิต | เครื่องมือกล",
        "สาขาวิชาไฟฟ้า | ไฟฟ้าควบคุมทางอุตสาหกรรม",
        "สาขาวิชาการตลาด | การตลาด",
      ],
      items_three: [
        "สาขาวิชาการบัญชี | การบัญชี",
        "สาขาวิชาเทคโนโลยีธุรกิจดิจิทัล | ธุรกิจอีคอมเมิร์ซ (คอมพิวเตอร์ธุรกิจ)",
        "สาขาวิชาการตลาด | การตลาด",
      ],
      items_aumper: [],
      items_jangwat: [],
      items_tambon: [],
      items_postcode: [],
      items_th: ["นาย", "นางสาว", "นาง", "เด็กชาย", "เด็กหญิง"],
      items_eng: ["Mr.", "Miss.", "Mrs.", "Master"],
      item_religion: ["พุทธ", "คริสต์", "อิสลาม"],
      item_race: [
        "ไทย",
        "ไทยลื้อ",
        "ไทยจีน",
        "ลัวะ",
        "กะเหรี่ยง",
        "มอญ",
        "ลาว",
        "ญี่ปุ่น",
      ],
      item_nationality: [
        "ไทย",
        "ไทยลื้อ",
        "อาข่า",
        "ลัวะ",
        "พม่า",
        "ลาว",
        "จีน",
        "ญี่ปุ่น",
        "ยังไม่ได้สัญชาติไทย",
      ],
      item_education: [
        { text: "มัธยมศึกษาตอนต้น (ม.3)", value: 1 },
        { text: "มัธยมศึกษาตอนปลาย (ม.6)", value: 2 },
        { text: "ประกาศนียบัตรวิชาชีพ (ปวช.)", value: 3 },
      ],
      picker: new Date().toISOString().substr(0, 10),
      format_picker: new Date().toISOString().substr(0, 10),
      show_picker: new Date().toISOString().substr(0, 10),
      modal: false,
      register: {
        title_name_th: "",
        firstname_th: "",
        lastname_th: "",
        title_name_eng: "",
        firstname_eng: "",
        lastname_eng: "",
        nickname: "",
        age: 0,
        religion: "",
        race: "",
        nationality: "",
        rank_education: "",
        school_province: "",
        school_name: "",
        gpa: "",
      },
      address: {
        id_house: "",
        moo: "",
        name_mooban: "",
        road: "",
        province: "",
        county: "",
        district: "",
        id_address: "",
        phone_std: "",
        email: "",
      },
      parents: {
        first_name_father: "",
        last_name_father: "",
        first_name_mother: "",
        last_name_mother: "",
        phone_parents: "",
      },
      files: [
        {
          bin: null,
          type: "บัตรประจำตัวประชาชน",
          progress: 0,
        },
        {
          bin: null,
          type: "ทะเบียนบ้าน",
          progress: 0,
        },
        {
          bin: null,
          type: "ภาพถ่ายสองนิ้ว",
          progress: 0,
        },
        {
          bin: null,
          type: "ใบวุฒิ รบ.1 / เกรดเฉลี่ยเทอมล่าสุด",
          progress: 0,
        },
        {
          bin: null,
          type: "บัตรประชาชนผู้ปกครอง",
          progress: 0,
        },
        {
          bin: null,
          type: "ทะเบียนบ้าน",
          progress: 0,
        },
      ],
      fileUpload: [],
      show_rank_education: "",
      show_school_province: "",
      show_province: "",
      show_county: "",
      show_district: "",
      show_id_address: "",
    };
  },
  mounted() {
    this.$refs.person_number.focus();
    this.formatDate(this.picker);
    this.fetchProvince();
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    async page(val) {
      if (val === 2) {
        this.showAmper(this.address.county);
        this.showTambon(this.address.district);
        this.showIdAddress(this.address.id_address);
        this.showJangwat(this.address.province);
        this.showJangwatSchool(this.register.school_province);
        this.showEducation();
        this.validateText("nth");
        this.validateText("fth");
        this.validateText("lth");
        this.validateText("feng");
        this.validateText("leng");
        this.validateEmail();
        this.validatePhoneNumber("std");
        this.validatePhoneNumber("par");
        this.validateGpa();
      } else if (val === 3) {
        const data = await fetchInfo(this.person_number);
        if (data) {
          // console.log(data);
          this.fileUpload = data.files;
        }
      }
    },
    data_branch() {
      if (this.data_branch !== "") {
        this.status.btn_branch = false;
      } else if (this.data_branch === "") {
        this.status.btn_branch = true;
      }
    },
    person_number() {
      const pattern = /^[0-9]{13}$/;
      this.validate.v_person_number = pattern.test(this.person_number);
    },
  },
  methods: {
    mouseOver(e) {
      if (e === 1) {
        this.tooltip.step1 = !this.tooltip.step1;
      } else if (e === 2) {
        this.tooltip.step2 = !this.tooltip.step2;
      } else if (e === 3) {
        this.tooltip.step3 = !this.tooltip.step3;
      } else if (e === 4) {
        this.tooltip.step4 = !this.tooltip.step4;
      }
    },
    showAmper(data) {
      this.items_aumper.forEach((e) => {
        if (data === e.id) {
          this.show_county = e.name;
        }
      });
    },
    showTambon(data) {
      this.items_tambon.forEach((e) => {
        if (data === e.id) {
          this.show_district = e.name;
        }
      });
    },
    showIdAddress(data) {
      this.items_postcode.forEach((e) => {
        if (data === e.id) {
          this.show_id_address = e.name;
        }
      });
    },
    showJangwat(data) {
      this.items_jangwat.forEach((e) => {
        if (data === e.id) {
          this.show_province = e.name;
        }
      });
    },
    showJangwatSchool(data) {
      this.items_jangwat.forEach((e) => {
        if (data === e.id) {
          this.show_school_province = e.name;
        }
      });
    },
    showEducation() {
      this.item_education.forEach((e) => {
        if (this.register.rank_education === e.value) {
          this.show_rank_education = e.text;
        }
      });
    },
    validateGpa() {
      const pattern = /^([0-9]{1}[.][0-9]{2})$/;
      this.validate.v_gpa = pattern.test(this.register.gpa);
    },
    validatePersonNumber() {
      const pattern = /^[0-9]{13}$/;
      this.validate.v_person_number = pattern.test(this.person_number);
    },
    validateText(e) {
      const patterneng = /^([A-Za-z])+$/;
      const patternth = /^[ก-๏]+$/;
      if (e === "nth") {
        this.validate.v_nickname_stu_th = patternth.test(
          this.register.nickname
        );
      }
      if (e === "fth") {
        this.validate.v_firstname_stu_th = patternth.test(
          this.register.firstname_th
        );
      }
      if (e === "lth") {
        this.validate.v_lastname_stu_th = patternth.test(
          this.register.lastname_th
        );
      }
      if (e === "feng") {
        this.validate.v_firstname_stu_eng = patterneng.test(
          this.register.firstname_eng
        );
        this.register.firstname_eng = this.register.firstname_eng.toUpperCase();
      }
      if (e === "leng") {
        this.validate.v_lastname_stu_eng = patterneng.test(
          this.register.lastname_eng
        );
        this.register.lastname_eng = this.register.lastname_eng.toUpperCase();
      }
    },
    validateEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.validate.v_email = pattern.test(this.address.email);
    },
    validatePhoneNumber(e) {
      const pattern = /^0([0-9]{2}[0-9]{3}[0-9]{4})$/;
      if (e === "std") {
        this.validate.v_phone_student = pattern.test(this.address.phone_std);
      }
      if (e === "par") {
        this.validate.v_phone_parents = pattern.test(
          this.parents.phone_parents
        );
      }
    },
    async nextStep(n) {
      if (n == 4) {
        window.scrollTo(0, 0);
        this.page = 3;
        return;
      } else {
        const data = {
          reg_type: this.branch,
          id_card: this.person_number,
          namePrefix: this.register.title_name_th,
          nameFirst: this.register.firstname_th,
          nameLast: this.register.lastname_th,
          namePrefixE: this.register.title_name_eng,
          nameFirstE: this.register.firstname_eng,
          nameLastE: this.register.lastname_eng,
          bDate: this.format_picker,
          AD_houseNo: this.address.id_house,
          AD_villageNo: this.address.moo,
          AD_villageName: this.address.name_mooban,
          AD_roadName: this.address.road,
          AD_province_id: this.address.province,
          AD_district_id: this.address.county,
          AD_subDistrict_id: this.address.district,
          AD_postcode: this.address.id_address,
          CT_phoneNumber: this.address.phone_std,
          CT_facebook: null,
          CT_line: null,
          email: this.address.email,
          PI_Contact: this.parents.phone_parents,
          PI_FatherName: `${this.parents.first_name_father} ${this.parents.last_name_father}`,
          PI_MotherName: `${this.parents.first_name_mother} ${this.parents.last_name_mother}`,
          course: this.data_branch,
          ED_highestEduLevel: this.register.rank_education,
          ED_Province: this.register.school_province,
          ED_PrevSchool: this.register.school_name,
          ED_GPA: this.register.gpa,
          stage: n + 1,
        };
        if (this.approve == 0) {
          saveStage(data);
        }
      }
      this.e1 = n + 1;
      window.scrollTo(0, 0);
    },
    backStep(n) {
      window.scrollTo(0, 0);
      this.e1 = n - 1;
    },
    async regisPersonId() {
      this.loading = true;
      const data = await fetchInfo(this.person_number);
      this.loading = false;
      if (data) {
        this.branch = data.reg_type;
        this.person_number = data.id_card;
        this.register.title_name_th = data.namePrefix;
        this.register.firstname_th = data.nameFirst;
        this.register.lastname_th = data.nameLast;
        this.register.title_name_eng = data.namePrefixE;
        this.register.firstname_eng = data.nameFirstE;
        this.register.lastname_eng = data.nameLastE;
        this.picker = data.bDate;
        await this.formatDate(this.picker);
        this.address.id_house = data.AD_houseNo;
        this.address.moo = data.AD_villageNo;
        this.address.name_mooban = data.AD_villageName;
        this.address.road = data.AD_roadName;
        this.address.province = ("000" + data.AD_province_id).slice(-3);
        this.address.county = data.AD_district_id;
        this.address.district = data.AD_subDistrict_id;
        this.address.id_address = data.AD_postcode;
        this.address.phone_std = data.CT_phoneNumber;
        this.address.email = data.email;
        this.data_branch = data.course;
        this.register.rank_education = data.ED_highestEduLevel;
        this.register.school_province = ("000" + data.ED_Province).slice(-3);
        this.register.school_name = data.ED_PrevSchool;
        this.register.gpa = data.ED_GPA;
        this.approve = data.approval_status;
        if (this.address.province) {
          this.fetchAumphoe(this.address.province);
        }
        if (data.AD_district_id) {
          this.fetchTambon(this.address.province, data.AD_district_id);
        }
        if (data.PI_FatherName) {
          const arr = data.PI_FatherName.split(" ");
          this.parents.first_name_father = arr[0];
          this.parents.last_name_father = arr[1];
        }
        if (data.PI_MotherName) {
          const arr = data.PI_MotherName.split(" ");
          this.parents.first_name_mother = arr[0];
          this.parents.last_name_mother = arr[1];
        }
        this.parents.phone_parents = data.PI_Contact;
        this.fileUpload = data.files;
        if (data.approval_status > 0) {
          this.page = 3;
        } else {
          this.page = 2;
        }
      }
      this.page = 1;
    },
    selectBranch() {
      this.page = 2;
    },
    async formatDate(date) {
      await this.processAge();
      if (!date) return null;
      let [year, month, day] = date.split("-");
      let a = parseInt(year);
      let b = parseInt(year);
      b = b + 543;
      this.format_picker = `${day}/${month}/${a}`;
      this.show_picker = `${day}/${month}/${b}`;
    },
    processAge() {
      let ageInMillis = new Date() - new Date(this.picker);
      let age = Math.floor(ageInMillis / 31557600000);
      this.register.age = age;
    },
    uploadFileSync(index) {
      uploadFile(
        this.person_number,
        this.files[index].bin,
        this.files[index].type,
        (percent) => {
          this.files[index].progress = percent;
        }
      );
    },
    async fetchProvince() {
      this.items_jangwat = await fetchProvince();
    },
    async fetchAumphoe(province_id) {
      this.items_aumper = await fetchAmphoe(province_id);
    },
    async fetchTambon(province_id, aumphoe_id) {
      this.items_tambon = await fetchTambon(province_id, aumphoe_id);
      this.fetchPostcode(province_id, aumphoe_id);
    },
    async fetchPostcode(province_id, tambon_id) {
      this.items_postcode = await fetchPostcode(province_id, tambon_id);
    },
    async submit() {
      this.loading = true;
      try {
        const result = await submit(this.person_number);
        this.snackbar.open = true;
        if (result) {
          this.snackbar.text = "บันทึกข้อมูลสำเร็จ";
          this.approve = 1;
        } else {
          this.snackbar.text = "บันทึกข้อมูลล้มเหลว";
          this.snackbar.color = "danger";
        }
        this.loading = false;
      } catch (e) {
        this.dialog.open = true;
        this.dialog.text = "ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบข้อมูล";
        if (e.response) {
          this.dialog.text = e.response.data.message.join("<br/>");
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cover-image {
  border-radius: 8px 8px 0px 0px;
}
.step-text {
  font-size: 14px !important;
}
.current i {
  color: #fff;
}
button {
  border-radius: 0px;
  padding: 20px !important;
  margin-bottom: 10px;
}
</style>